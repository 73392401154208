import * as React from 'react'
import { useRouter } from 'next/router'
import { Button, BUTTON_TYPE } from '@pig-common/components/Button'
import useSystemStatus from '@pig-common/hooks/useSystemStatus'
import { getLineWebLoginUrl, SCOPE_ENUM } from '@pig-common/services/line'
import { COOKIES_KEY, useCookies } from '@pig-common/utils/cookies'
import { ReactComponent as LineLogo } from '@pig-common/public/images/Line-Logo.svg'
import { ButtonProps } from '../CommonButton/CommonButton'

export type ILineButtonProps = ButtonProps & {
  redirectPath: string
}

const LineButton: React.FunctionComponent<ILineButtonProps> = ({
  wrapperStyle,
  ...props
}) => {
  const { redirectPath } = props
  const router = useRouter()
  const { refetch: refreshSystemStatus } = useSystemStatus({ enabled: false })
  const [, setCookies] = useCookies<string>()

  const checkLineHandler = async () => {
    await refreshSystemStatus()
    const loginState = Buffer.from(Math.random().toString())
      .toString('base64')
      .substring(10, 5)
    const nonce = Buffer.from(Math.random().toString())
      .toString('base64')
      .substring(10, 5)
    const scope = [SCOPE_ENUM.PROFILE, SCOPE_ENUM.OPENID]
    setCookies(COOKIES_KEY.LINE_STATE, loginState)
    router.replace(
      getLineWebLoginUrl(
        `${window.location.origin}${redirectPath}`,
        loginState,
        nonce,
        scope,
      ),
    )
  }

  return (
    <Button
      {...props}
      id="checkline-next-button"
      type={BUTTON_TYPE.OUTLINE_BLACK}
      className="ps-login__button-line-submit"
      onClick={checkLineHandler}
      nativeType="submit"
      prependContent={<LineLogo className="ps-login__line-icon" />}
      wrapperStyle={{
        ...wrapperStyle,
        borderWidth: '1',
        display: 'flex',
        border: '1px solid',
      }}
    >
      สมัครสมาชิก/เข้าสู่ระบบด้วย LINE
    </Button>
  )
}

export default LineButton
