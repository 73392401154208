import React, { useEffect, useState } from 'react'
import { useResponsiveState } from '@pig-common/hooks/useResponsive'
import { BaseButtonProps } from '../Button.type'

export const HOLDING_CLICK_DURATION = 300

export enum BUTTON_TYPE {
  NONE = 'none-cta',
  CTA_1 = 'cta-1',
  CTA_2 = 'cta-2',
  CTA_3 = 'cta-3',
  CTA_4 = 'cta-4',
  OUTLINE_BLACK = 'outline-black',
  OUTLINE_WHITE = 'outline-white',
  OUTLINE_GREY = 'outline-grey',
  OUTLINE_CTA_1 = 'outline-cta-1',
  OUTLINE_CTA_1_DISABLED = 'outline-cta-1-disabled',
  PLAIN_TEXT = 'plain-text',
}

export enum BUTTON_SIZE {
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
}

export type ButtonProps = BaseButtonProps & {
  type?: BUTTON_TYPE
  size?: BUTTON_SIZE
  className?: string
  overrideWidth?: string | number // ex. 20, '20px'
  overrideHeight?: string | number // ex. 20, '20px'
  overridePadding?: string | number // ex. 0, '20px 16px 10px 16px'
  requiedSpanWrapBody?: boolean
  buttonForm?: boolean
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id = 'button',
      prependContent,
      children,
      appendContent,
      type = BUTTON_TYPE.NONE,
      size = BUTTON_SIZE.MD,
      buttonForm = false,
      nativeType = 'button',
      onClick,
      disabled,
      className = '',
      overrideWidth,
      overrideHeight,
      overridePadding,
      requiedSpanWrapBody = true,
      wrapperStyle,
    },
    buttonRef,
  ) => {
    const [overrideStyle, setOverrideStyle] = useState({})
    const { isMD } = useResponsiveState()
    useEffect(() => {
      let style: React.CSSProperties = overrideStyle
      if (buttonForm) {
        style = {
          ...style,
          width: isMD ? 196 : 160,
          height: 40,
        }
      }
      if (overrideWidth != null) {
        style.width = overrideWidth
      }
      if (overrideHeight != null) {
        style.height = overrideHeight
      }
      if (overridePadding != null) {
        style.padding = overridePadding
      }
      if (
        overrideWidth != null ||
        overrideHeight != null ||
        overridePadding != null ||
        buttonForm
      ) {
        setOverrideStyle(style)
      }
    }, [overrideWidth, overrideHeight, overridePadding])

    const startButtonAnimation = (
      event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    ) => {
      const button = event.currentTarget
      button.classList.add('ps-button--clicked')
      setTimeout(() => {
        button.classList.remove('ps-button--clicked')
      }, HOLDING_CLICK_DURATION)

      if (type !== BUTTON_TYPE.CTA_1 && type !== BUTTON_TYPE.CTA_2) return

      const circle = document.createElement('span')
      const diameter = Math.max(button.clientWidth, button.clientHeight)
      const radius = diameter / 2

      circle.style.height = `${diameter}px`
      circle.style.width = `${diameter}px`
      circle.style.left = `${event.clientX - button.offsetLeft - radius}px`
      circle.style.top = `${event.clientY - button.offsetTop - radius}px`
      circle.classList.add('ps-button__coat', 'ps-button__coat--tap')

      const ripple = button.getElementsByClassName('ps-button__coat--tap')[0]
      if (ripple) ripple.remove()

      button.appendChild(circle)
    }

    return (
      <button
        id={`${id}${disabled ? '--disabled' : ''}`}
        data-testid={`${id}${disabled ? '--disabled' : ''}`}
        onClick={onClick}
        onPointerDown={(e) => {
          if (!disabled) startButtonAnimation(e)
        }}
        className={`ps-button ps-button--${type} ps-button--${size} ${className}`}
        disabled={disabled}
        ref={buttonRef}
        type={nativeType}
        style={{ ...overrideStyle, ...wrapperStyle }}
      >
        {prependContent && (
          <span className={`ps-button__prepend ps-button__prepend--${size}`}>
            {prependContent}
          </span>
        )}
        {requiedSpanWrapBody ? (
          <span className="ps-button__body">{children}</span>
        ) : (
          children
        )}
        {appendContent && (
          <span className={`ps-button__append ps-button__append--${size}`}>
            {appendContent}
          </span>
        )}
      </button>
    )
  },
)

Button.displayName = 'Button'

export default Button
