import { useMediaQuery } from 'react-responsive'
import { breakPoints } from '@pig-common/utils/responsive'

export type ResponsesiveState = {
  isSM: boolean
  isMD: boolean
  isLG: boolean
  isXL: boolean
}

export const useResponsiveState = (): ResponsesiveState => {
  const isSM = useMediaQuery({ minWidth: breakPoints.sm })
  const isMD = useMediaQuery({ minWidth: breakPoints.md })
  const isLG = useMediaQuery({ minWidth: breakPoints.lg })
  const isXL = useMediaQuery({ minWidth: breakPoints.xl })
  return {
    isSM,
    isMD,
    isLG,
    isXL,
  }
}
