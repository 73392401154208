import { IPigSpinFormData } from '@pig-common/services/service.type'

export type UTM = {
  utm_url?: string
  utm_id?: string
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

export type RegisterWithAccessTokenRequest = {
  access_token: string
  token_type: string
  refresh_token: string
  expires_in: number
  scope: string
  avatar: string
  displayName: string
  utmData?: UTM
}

// NOTE : PHP API Layer
export interface IRegisterWithAccessTokenFormData extends IPigSpinFormData {
  access_token: string
  token_type: string
  refresh_token: string
  expires_in: number
  scope: string
  device_token: string
  avatar: string
  display_name: string
  utm_url?: string
  utm_id?: string
  utm_source?: string
  utm_medium?: string
  utm_campaign?: string
  utm_term?: string
  utm_content?: string
}

export enum REGISTER_WITH_ACCESS_TOKEN_STATUS {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  REGISTER_FAIL = 'RegisterFail',
  CLOSE_REGISTER = 'CloseRegister',
}

export type RegisterWithAccessTokenData = {
  player_username: string
  user_code: string
  game_token: string
  display_name: string
  avatar: string
}

export enum REGISTER_WITH_ACCESS_TOKEN_ERROR_CODE {
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
  CLOSE_REGISTER = 'CLOSE_REGISTER',
}

export type RegisterWithAccessTokenResponse = {
  Status: REGISTER_WITH_ACCESS_TOKEN_STATUS
  Message: string
  Data: RegisterWithAccessTokenData
  Code: number
  ErrorCode?: REGISTER_WITH_ACCESS_TOKEN_ERROR_CODE
  cfid: string
}

// eslint-disable-next-line max-len
export const requestRegisterWithAccessToken = (
  request: RegisterWithAccessTokenRequest,
) => {
  // TODO : might useful when register with line
  // let formData: IRegisterWithAccessTokenFormData = {
  //   action: API_ACTION.REGISTER_LINE,
  //   access_token: request.access_token,
  //   token_type: request.token_type,
  //   refresh_token: request.refresh_token,
  //   expires_in: request.expires_in,
  //   scope: request.scope,
  //   avatar: request.avatar,
  //   display_name: request.displayName,
  //   device_token: uuidv4(),
  // };
  // if (request.utmData) {
  //   formData = {
  //     ...formData,
  //     utm_url: request.utmData?.utm_url,
  //     utm_id: request.utmData?.utm_id,
  //     utm_source: request.utmData?.utm_source,
  //     utm_medium: request.utmData?.utm_medium,
  //     utm_campaign: request.utmData?.utm_campaign,
  //     utm_term: request.utmData?.utm_term,
  //     utm_content: request.utmData?.utm_content,
  //   };
  // }
  // const requestConfig: AxiosRequestConfig = {
  //   url: '/request.php',
  //   method: 'POST',
  //   timeout: undefined, // NOTE : [SEL-2490] fix timeout while register with high latency network
  // };
  // return fetchWithFormData<RegisterWithAccessTokenResponse>(
  //   phpApiInstance,
  //   requestConfig,
  //   formData,
  // );
}

export default requestRegisterWithAccessToken
