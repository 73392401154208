import React from 'react'
import Link from 'next/link'
import classNames from 'classnames'
import { BaseButtonProps } from '../Button.type'

export type TextLinkProps = BaseButtonProps & {
  link?: string
  withExtraPadding?: boolean
  className?: string
}

export const TextLink = ({
  id = 'text-link',
  link,
  prependContent,
  children,
  appendContent,
  disabled,
  withExtraPadding,
  className,
  onClick,
}: TextLinkProps) => {
  let textLinkClassName = 'ps-text-link'
  if (disabled) textLinkClassName += ' ps-text-link--disabled'
  if (withExtraPadding) textLinkClassName += ' ps-text-link--extra-padding'
  if (!prependContent && appendContent) {
    textLinkClassName += ' ps-text-link--shadow-padding-left'
  } else if (!appendContent && prependContent) {
    textLinkClassName += ' ps-text-link--shadow-padding-right'
  }
  textLinkClassName = classNames(textLinkClassName, className)

  const renderContent = () => {
    return (
      <>
        {prependContent && (
          <span className="ps-text-link__prepend">{prependContent}</span>
        )}
        <span className="ps-text-link__body">{children}</span>
        {appendContent && (
          <span className="ps-text-link__append">{appendContent}</span>
        )}
      </>
    )
  }
  const contentOutput = React.useMemo(renderContent, [
    prependContent,
    children,
    appendContent,
  ])

  const renderTextLink = () => {
    if (link && !disabled) {
      return (
        <Link href={link || '/'}>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a id={id} data-testid={id} className={textLinkClassName}>
            {contentOutput}
          </a>
        </Link>
      )
    }
    if (onClick && !disabled) {
      return (
        <button
          id={id}
          data-testid={id}
          className={textLinkClassName}
          onClick={onClick}
        >
          {contentOutput}
        </button>
      )
    }
    return (
      <div id={id} data-testid={id} className={textLinkClassName}>
        {contentOutput}
      </div>
    )
  }

  return renderTextLink()
}

export default TextLink
