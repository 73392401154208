import { AxiosRequestConfig } from 'axios'
import { useRecoilState, useRecoilValue } from 'recoil'

import { BaseApiResponse } from '@pig-common/services/service.type'
import { fetchWithJSON } from '@pig-common/utils/api/fetch'
import { favoriteGameStateCommon, authStateCommon } from '@pig-common/recoils'

import { useAxios } from './useAxios'

export type FavoriteGameItem = {
  customer_code?: string
  game_id: string
  game_code: string
  is_favorite: boolean
}

export type FavoriteGameResponse = BaseApiResponse<{
  user_code: string
  /** @description list of game_code */
  game_fav_list: string[]
}>

export type UpdateFavoriteGameResponse = BaseApiResponse<FavoriteGameItem>

export type FavoriteGameHook = {
  callGetFavoriteGame: () => Promise<string[]>
  callUpdateFavoriteGame: (
    request: FavoriteGameItem,
  ) => Promise<UpdateFavoriteGameResponse>
}

export const useFavoriteGame = (): FavoriteGameHook => {
  const { pigspinApiInstance } = useAxios()
  const auth = useRecoilValue(authStateCommon.authState)
  const [favoriteState, setFavoriteState] = useRecoilState(
    favoriteGameStateCommon.favoriteGameState,
  )

  const callGetFavoriteGame = async (): Promise<string[]> => {
    setFavoriteState({ ...favoriteState, isSuccess: false, isLoading: true })
    const requestConfig: AxiosRequestConfig = {
      url: `/v1/user/get_favorite_game/${auth.customerCode}`,
      method: 'GET',
    }
    const favoriteGameResponse = await fetchWithJSON<FavoriteGameResponse>(
      pigspinApiInstance,
      requestConfig,
    )
    const favGameCodes = favoriteGameResponse.data?.game_fav_list as string[]
    setFavoriteState({
      ...favoriteGameStateCommon.defaultFavoriteGameState,
      isSuccess: true,
      data: favGameCodes,
    })
    return favGameCodes
  }

  const callUpdateFavoriteGame = async (
    request: FavoriteGameItem,
  ): Promise<UpdateFavoriteGameResponse> => {
    const payload: FavoriteGameItem = {
      ...request,
      customer_code: auth.customerCode || request.customer_code,
    }
    const requestConfig: AxiosRequestConfig = {
      url: '/v1/user/update_favorite_game',
      method: 'PUT',
    }
    return fetchWithJSON<UpdateFavoriteGameResponse>(
      pigspinApiInstance,
      requestConfig,
      payload,
    )
  }

  return {
    callGetFavoriteGame,
    callUpdateFavoriteGame,
  }
}
