import React from 'react'
import { motion } from 'framer-motion'
import { Loading } from '@pig-common/components/Loading'

type LoadMoreButtonProps = {
  isLoading?: boolean
  isNoMore?: boolean
  loadMoreText: string
  noMoreText: string
  onLoadMore: () => void
}

const LoadMoreButton = ({
  isLoading,
  isNoMore,
  loadMoreText,
  noMoreText,
  onLoadMore,
}: LoadMoreButtonProps) => {
  return (
    <>
      {isLoading ? (
        <div className="ps-champion-load-more">
          <motion.div
            key="motion-qr-loading"
            id="wallet-qr-deposit-loader"
            className="ps-deposit-qr__loader"
          >
            <Loading />
          </motion.div>
        </div>
      ) : (
        <>
          {isNoMore ? (
            <div className="ps-champion-load-more ps-champion-load-more--ended">
              {noMoreText}
            </div>
          ) : (
            <div
              className="d-flex justify-content-center mt-2"
              data-testid="load-more-button"
            >
              {/* <Button
                id="load-big-win-more-button"
                className="py-1 px-3"
                type={BUTTON_TYPE.OUTLINE_CTA_1}
                onClick={onLoadMore}
              >
                {loadMoreText}
              </Button> */}
            </div>
          )}
        </>
      )}
    </>
  )
}

export default LoadMoreButton
