import React, { useEffect, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import classNames from 'classnames'

import { COOKIES_KEY, useCookies } from '@pig-common/utils/cookies'
import { useFavoriteGame } from '@pig-common/hooks/useFavoriteGame'

import CommonButton, {
  ButtonProps,
  BUTTON_TYPE,
} from '../CommonButton/CommonButton'

export type FavoriteButtonProps = ButtonProps & {
  isFavorited: boolean
  gameCode: string
  gameId: string
  overriedIconSize?: string | number
  autoFavoriteRequest?: boolean
  autoRedirect?: boolean
  redirectPath?: string
  customerCode?: string
  isOnDarkSurface?: boolean
}

export const FavoriteButton = React.forwardRef<
  HTMLButtonElement,
  FavoriteButtonProps
>(
  (
    {
      id,
      onClick,
      disabled,
      className,
      overrideWidth = 24,
      overrideHeight = 24,
      overridePadding = 0,
      overriedIconSize = 20,
      isFavorited: isFavoritedProps = false,
      gameCode,
      gameId,
      customerCode,
      autoFavoriteRequest = true,
      autoRedirect = true,
      redirectPath = '/login',
      isOnDarkSurface = false,
    },
    buttonRef,
  ) => {
    const router = useRouter()
    const [cookies, ,] = useCookies<string>([COOKIES_KEY.CFID, COOKIES_KEY.UID])
    const { callUpdateFavoriteGame } = useFavoriteGame()
    const [isLoading, setLoading] = useState<boolean>(false)
    const [isFavorited, setFavorited] = useState<boolean>(isFavoritedProps)

    const favoriteStyle = {
      fontSize: overriedIconSize,
    }

    const handleOnClicked = async (e: any) => {
      const isAuth = !!(cookies.uid || cookies.cfid)
      if (autoRedirect && !isAuth) {
        router.push(redirectPath)
        return
      }
      setLoading(true)
      if (autoFavoriteRequest) {
        // request favorite api...
        const resp = await callUpdateFavoriteGame({
          customer_code: customerCode,
          game_id: gameId,
          game_code: gameCode,
          is_favorite: !isFavorited,
        })
        setFavorited(resp.data.is_favorite)
        setLoading(false)
      }
      if (onClick) onClick(e)
    }

    useEffect(() => {
      setFavorited(isFavoritedProps)
    }, [isFavoritedProps])

    const getIconColorClassNames = () => ({
      'ps-favorite-button--on-dark-bg': isOnDarkSurface,
      'ps-favorite-button--on-light-bg ps-favorite-button--on-light-bg--active':
        !isOnDarkSurface && isFavorited,
      'ps-favorite-button--on-light-bg': !isOnDarkSurface && !isFavorited,
    })
    const iconColorClassNames = useMemo(getIconColorClassNames, [
      isOnDarkSurface,
      isFavorited,
    ])

    return (
      <CommonButton
        id={id}
        {...(!isLoading ? { onClick: handleOnClicked } : {})}
        disabled={disabled}
        className={classNames(
          'ps-favorite-button',
          iconColorClassNames,
          className,
        )}
        overrideWidth={overrideWidth}
        overrideHeight={overrideHeight}
        overridePadding={overridePadding}
        type={BUTTON_TYPE.PLAIN_TEXT}
        ref={buttonRef}
        requiedSpanWrapBody={false}
      >
        {isLoading ? (
          <i
            className="ps-icon-star-filled ps-favorite-button--disable"
            style={favoriteStyle}
          />
        ) : (
          <>
            {isFavorited ? (
              <i className="ps-icon-star-filled" style={favoriteStyle} />
            ) : (
              <i className="ps-icon-star" style={favoriteStyle} />
            )}
          </>
        )}
      </CommonButton>
    )
  },
)

FavoriteButton.displayName = 'FavoriteButton'

export default FavoriteButton
