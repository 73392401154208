import React from 'react'

export type LoadingProps = { extraClassName?: string }
export type LoaderFilterProps = {
  switcher: boolean
}

const Loading = ({ extraClassName = '' }: LoadingProps) => {
  const loadingClass = `ps-loading ${extraClassName}`
  return <div className={loadingClass} style={{ margin: '32px auto' }} />
}

export const LoaderFilter = ({ switcher }: LoaderFilterProps): JSX.Element => {
  const isActive = switcher ? 'ps-loader-filter--active' : ''
  return (
    <div
      className={`ps-loader-filter ${isActive}`}
      data-testid="loading-filter"
    >
      <span className="ps-icon-loading" />
      <div className="ps-loader-filter__loading-animated">
        <Loading />
      </div>
    </div>
  )
}

export const PigSpinLoading: React.FC = () => {
  return <LoaderFilter switcher />
}

export default Loading
